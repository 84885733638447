<script>
import CrudFieldSelectorCardDeckReward from '@/components/forms/crud-fields/CrudFieldSelectorCardDeckReward.vue'

export default {
  components: { CrudFieldSelectorCardDeckReward }
}
</script>

<template>
  <crud-details-page
    api="cosmetics/skin-group"
    disable-top-margin
    re-load-data-after-save
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.SkinGroupType"
          :visible-options="['CardGroup', 'EventCardGroup']"
          required
        />
        <!-- CardGroupReward -->
        <CrudFieldSelectorCardDeckReward api-field-name="CardGroupReward" />
        <!-- CreateCardQualities -->
        <crud-field-text
          api-field-name="CreateCardQualities"
          vertical
        >
          <template #fieldAppend>
            <div class="font-related-xs text-neutral-500">
              (9 comma separated values, e.g. "1,1,2,2,3,3,4,4,5")
            </div>
          </template>
        </crud-field-text>
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetPhoto -->
        <crud-field-selector-files api-field-name="AssetPhoto" />
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
        <!-- SeasonDayStart -->
        <crud-field-number api-field-name="SeasonDayStart" />
      </fields-col>
      <fields-col :sm="8">
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
        <!-- Color -->
        <crud-field-text api-field-name="Color" />
        <!-- AllowDuplicateExchange -->
        <crud-field-switcher api-field-name="AllowDuplicateExchange" />
      </fields-col>

      <section-divider>Cards</section-divider>
      <template
        v-for="i in 9"
        :key="i"
      >
        <crud-field-selector-skin
          :sm="8"
          :api-field-name="`Card${i}`"
          :init-filters="{ Type: 'Card' }"
          is-card
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Weight${i}A`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Weight${i}B`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Weight${i}C`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`RequiredLevel${i}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
