<script>
export default {
  data() {
    return {
      loading: {}
    }
  },
  computed: {
    atLeast1ChanceCommon() {
      return (form) => {
        const chance =
          100 *
          (1 -
          (1 -
          form.WeightCommon /
          (form.WeightCommon + form.WeightUncommon + form.WeightRare + form.WeightEpic + form.WeightLegendary)) ** form.Quantity)
        return chance.toFixed(2)
      }
    },
    atLeast1ChanceUncommon() {
      return (form) => {
        const chance =
          100 *
          (1 -
          (1 -
          form.WeightUncommon /
          (form.WeightCommon + form.WeightUncommon + form.WeightRare + form.WeightEpic + form.WeightLegendary)) ** form.Quantity)
        return chance.toFixed(2)
      }
    },
    atLeast1ChanceRare() {
      return (form) => {
        const chance =
          100 *
          (1 -
          (1 -
          form.WeightRare /
          (form.WeightCommon + form.WeightUncommon + form.WeightRare + form.WeightEpic + form.WeightLegendary)) ** form.Quantity)
        return chance.toFixed(2)
      }
    },
    atLeast1ChanceEpic() {
      return (form) => {
        const chance =
          100 *
          (1 -
          (1 -
          form.WeightEpic /
          (form.WeightCommon + form.WeightUncommon + form.WeightRare + form.WeightEpic + form.WeightLegendary)) ** form.Quantity)
        return chance.toFixed(2)
      }
    },
    atLeast1ChanceLegendary() {
      return (form) => {
        const chance =
          100 *
          (1 -
          (1 -
          form.WeightLegendary /
          (form.WeightCommon + form.WeightUncommon + form.WeightRare + form.WeightEpic + form.WeightLegendary)) ** form.Quantity)
        return chance.toFixed(2)
      }
    }
  },
  methods: {
    initItem(form) {
      form.simChestLevel = 5
      form.simEventId = 1
      form.simChestGroup = 0
      form.simSeasonDay = 1
      form.simChestIncomeCount = 100
      form.simChestIncomeSimulations = 25
      return form
    },
    simChest(form) {
      this.loading.simChest = true
      this.$axios
        .get(`/admin/api/proxy/request/`, {
          params: {
            data: [],
            env: 'dev',
            chest_id: form.Versioned.Root,
            event_id: form.simEventId,
            level: form.simChestLevel,
            group: form.simChestGroup,
            season_day: form.simSeasonDay,
            count: form.simChestIncomeCount,
            simulations: form.simChestIncomeSimulations,
            uri: '/proxy/simchest/',
            method: 'GET'
          }
        })
        .then(({ data }) => {
          this.$messageBox
            .confirm(data.logs, undefined, {
              confirmButtonText: 'OK',
              type: 'info',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              icon: 'none',
              customClass: 'md:max-w-screen-md'
            })
            .catch(() => {})
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.simChest = false
        })
    },
    simChestIncome(form) {
      this.loading.simChestIncome = true
      this.$axios
        .get(`/admin/api/proxy/request/`, {
          params: {
            data: [],
            env: 'dev',
            chest_id: form.Versioned.Root,
            event_id: form.simEventId,
            level: form.simChestLevel,
            group: form.simChestGroup,
            season_day: form.simSeasonDay,
            count: form.simChestIncomeCount,
            simulations: form.simChestIncomeSimulations,
            uri: '/proxy/simchestincome/',
            method: 'GET'
          }
        })
        .then(({ data }) => {
          this.$messageBox
            .confirm(data.logs, undefined, {
              confirmButtonText: 'OK',
              type: 'info',
              dangerouslyUseHTMLString: true,
              showCancelButton: false,
              icon: 'none',
              customClass: 'md:max-w-screen-md'
            })
            .catch(() => {})
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.simChestIncome = false
        })
    }
  }
}
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
  >
    <template #form="{ form }">
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['CardPack', 'EventCardPack']"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
        <!-- Quantity -->
        <crud-field-number api-field-name="Quantity" />
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
      </fields-col>
      <fields-col :sm="4">
        <!-- Price1 -->
        <crud-field-number api-field-name="Price1" />
        <!-- PriceSpecial -->
        <crud-field-number api-field-name="PriceSpecial" />
        <!-- PriceExchange -->
        <crud-field-number api-field-name="PriceExchange" />
        <!-- PriceExchangeReset -->
        <crud-field-number api-field-name="PriceExchangeReset" />
        <!-- ExchangeCooldownHours -->
        <crud-field-number api-field-name="ExchangeCooldownHours" />
      </fields-col>
      <fields-col :sm="2">
        <!-- IsEventChest -->
        <crud-field-switcher
          label="Only by key"
          api-field-name="IsEventChest"
        />
        <!-- IsAutoOpen -->
        <crud-field-switcher api-field-name="IsAutoOpen" />
        <!-- IsGoldenPack -->
        <crud-field-switcher api-field-name="IsGoldenPack" />
        <!-- IsGoldenPackNew -->
        <crud-field-switcher api-field-name="IsGoldenPackNew" />
        <!-- IsDiamondPack -->
        <crud-field-switcher api-field-name="IsDiamondPack" />
        <!-- IsDiamondPackNew -->
        <crud-field-switcher api-field-name="IsDiamondPackNew" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetChestAnimation -->
        <crud-field-selector-files api-field-name="AssetChestAnimation" />
        <!-- AssetBackground -->
        <crud-field-selector-files api-field-name="AssetBackground" />
        <!-- AssetBackground2 -->
        <crud-field-selector-files api-field-name="AssetBackground2" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetSeasonPreview -->
        <crud-field-selector-files api-field-name="AssetSeasonPreview" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files api-field-name="AssetChestPreview" />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files api-field-name="AssetSeasonBanner" />
      </fields-col>
      <section-divider>Loot settings</section-divider>
      <fields-col :sm="16">
        <!-- WeightCommon -->
        <crud-field-number
          :sm="12"
          api-field-name="WeightCommon"
        />
        <crud-field-slot
          :sm="12"
          label="At Least 1 Common Chance (in %)"
        >
          {{ atLeast1ChanceCommon(form) }}
        </crud-field-slot>
        <!-- WeightUncommon -->
        <crud-field-number
          :sm="12"
          api-field-name="WeightUncommon"
        />
        <crud-field-slot
          :sm="12"
          label="At Least 1 Uncommon Chance (in %)"
        >
          {{ atLeast1ChanceUncommon(form) }}
        </crud-field-slot>
        <!-- WeightRare -->
        <crud-field-number
          :sm="12"
          api-field-name="WeightRare"
        />
        <crud-field-slot
          :sm="12"
          label="At Least 1 Rare Chance (in %)"
        >
          {{ atLeast1ChanceRare(form) }}
        </crud-field-slot>
        <!-- WeightEpic -->
        <crud-field-number
          :sm="12"
          api-field-name="WeightEpic"
        />
        <crud-field-slot
          :sm="12"
          label="At Least 1 Epic Chance (in %)"
        >
          {{ atLeast1ChanceEpic(form) }}
        </crud-field-slot>
        <!-- WeightLegendary -->
        <crud-field-number
          :sm="12"
          api-field-name="WeightLegendary"
        />
        <crud-field-slot
          :sm="12"
          label="At Least 1 Legendary Chance (in %)"
        >
          {{ atLeast1ChanceLegendary(form) }}
        </crud-field-slot>
      </fields-col>
      <fields-col :sm="8">
        <!-- WeightEnergy1 -->
        <crud-field-number api-field-name="WeightEnergy1" />
        <!-- WeightEnergy2 -->
        <crud-field-number api-field-name="WeightEnergy2" />
        <!-- WeightEnergy3 -->
        <crud-field-number api-field-name="WeightEnergy3" />
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-card
        shadow="never"
        class="gs-font-scaled relative mt-6 mb-10"
      >
        <fields-col>
          <crud-field-slot
            :sm="4"
            label="Sim Pack"
            empty-label
          >
            <el-button
              class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
              :loading="loading.simChest"
              @click="simChest(form)"
            >
              Sim pack
            </el-button>
          </crud-field-slot>
          <crud-field-slot
            :sm="20"
            label="Sim Chest Income"
            empty-label
          >
            <el-button
              class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
              :loading="loading.simChestIncome"
              @click="simChestIncome(form)"
            >
              Sim pack income
            </el-button>
          </crud-field-slot>
          <crud-field-number
            :sm="4"
            api-field-name="simChestLevel"
            :max="400"
            label="Sim level"
            vertical
          >
            <template #fieldAppend>
              <div class="font-related-xs text-neutral-500">
                (cards have required level to drop)
              </div>
            </template>
          </crud-field-number>
          <crud-field-number
            :sm="4"
            api-field-name="simEventId"
            :min="1"
            label="Sim EventId"
            vertical
          >
            <template #fieldAppend>
              <div class="font-related-xs text-neutral-500">
                (correct event id)
              </div>
            </template>
          </crud-field-number>
          <crud-field-number
            :sm="4"
            api-field-name="simChestGroup"
            :max="2"
            label="Sim group"
            vertical
          >
            <template #fieldAppend>
              <div class="font-related-xs text-neutral-500">
                (groups 0,1,2)
              </div>
            </template>
          </crud-field-number>
          <crud-field-number
            :sm="4"
            api-field-name="simSeasonDay"
            :max="30"
            label="Sim season day"
          />
          <crud-field-number
            :sm="4"
            api-field-name="simChestIncomeCount"
            label="Number of packs"
          />
          <crud-field-number
            :sm="4"
            api-field-name="simChestIncomeSimulations"
            label="Nr of simulations"
          />
        </fields-col>
        <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
      </el-card>
      <template
        v-for="i in 5"
        :key="i"
      >
        <crud-field-selector-skin
          :sm="6"
          :api-field-name="`Skin${i}`"
        />
        <crud-field-number
          :sm="6"
          :api-field-name="`Quantity${i}`"
        />
        <crud-field-number
          :sm="6"
          :api-field-name="`Weight${i}`"
        />
        <crud-field-switcher
          :sm="6"
          :api-field-name="`IsPreview${i}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
